import { Link } from "eddev/routing"
import { Button } from "./Button"
import { useFooterBlock } from "@hooks/queries"
import { useNav } from "@hooks/useNav"

export function FooterHero() {
  const content = useFooterBlock(undefined)
  const navStatus = useNav()

  return (
    <div className="footerHero">
      {content.data?.siteSettings?.miscSettings && (
        <div className="flex flex-col md:flex-row oversize md:border-l relative z-40 md:h-[440px]">
          <div
            className={`${navStatus.open ? `w-full md:w-1/2` : `w-full md:w-1/2 lg:w-1/3`}  h-[380px] md:h-auto bg-hero flex flex-col justify-between py-6 px-5 md:px-6 transition-all ease-in-out duration-500`}
          >
            <div className="w-fill flex flex-col gap-8 md:gap-4">
              <div
                className="type-title-l-light md:type-title-m-light lg:type-title-l-light"
                dangerouslySetInnerHTML={{ __html: content.data.siteSettings.miscSettings.footerBlockHeading || "" }}
              />
              <div
                className="type-title-s-light"
                dangerouslySetInnerHTML={{ __html: content.data.siteSettings.miscSettings.footerBlockMessage || "" }}
              />
            </div>
            {content.data.siteSettings.miscSettings.footerBlockButton?.url && (
              <div className="flex">
                <Button
                  reverse
                  href={content.data.siteSettings.miscSettings.footerBlockButton.url}
                  target={content.data.siteSettings.miscSettings.footerBlockButton?.target ?? undefined}
                >
                  {content.data.siteSettings.miscSettings.footerBlockButton?.title || ""}
                </Button>
              </div>
            )}
          </div>
          <div
            className={`${navStatus.open ? `w-full md:w-1/2` : `w-full md:w-1/2 lg:w-2/3`} relative max-h-[380px] transition-all ease-in-out duration-500  md:h-0 md:pt-[440px]`}
          >
            {content.data.siteSettings.miscSettings.footerBlockVideo?.mediaItemUrl && (
              <video
                width="100%"
                playsInline
                autoPlay
                muted
                loop
                className="md:absolute md:top-1/2 md:left-0 md:-translate-y-1/2 md:w-full"
              >
                <source src={content.data.siteSettings.miscSettings.footerBlockVideo.mediaItemUrl} type="video/mp4" />
              </video>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
